

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextCard',
  props: {
      title: String,
      firstText: String,
      secondText: String,
      thirdText: String,
      imgPath: String,
  }
});
